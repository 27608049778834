<template>
  <form
    class="flex flex-col"
    v-if="user !== null"
    @submit.prevent="update">
    <nav class="flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <v-back
        :close="true"
        :fallback="$router.resolve({ name: 'account-detail' })"
      />
      <p
        class="font-semibold w-full ml-12 text-center"
        v-text="$t('the_account_detail.label_user_profile')">
      </p>
      <button
        type="submit"
        class="btn btn-blue rounded-full py-1 px-3 focus:shadow"
        :class="{ 'btn-loading': loading }"
        :disabled="loading"
        v-text="$t('general.button_save')">
      </button>
    </nav>
    <ul class="list-disc list-inside">
      <li
        v-if="error"
        v-text="error"
        class="form-error">
      </li>
      <li
        v-if="errors.has('firstname')"
        v-text="errors.first('firstname')"
        class="form-error">
      </li>
      <li
        v-if="errors.has('lastname')"
        v-text="errors.first('lastname')"
        class="form-error">
      </li>
      <li
        v-if="errors.has('phone')"
        v-text="errors.first('phone')"
        class="form-error">
      </li>
      <li
        v-if="errors.has('email')"
        v-text="errors.first('email')"
        class="form-error">
      </li>
    </ul>
    <div class="w-full flex justify-center py-6 border-b border-blue-500 relative">
      <img
        v-if="!avatar"
        src="@/assets/images/avatar_placeholder.svg"
        :alt="$t('general.label_profile_picture')"
        class="h-24 w-24 rounded-full"
      />
      <img
        v-if="avatar"
        :src="avatar"
        :alt="$t('general.label_profile_picture')"
        class="h-24 w-24 rounded-full"
      />
      <div class="h-24 w-24 absolute bg-black opacity-50 rounded-full"></div>
      <div
        class="h-24 w-24 absolute rounded-full flex
        justify-center items-center pointer-events-none"
      >
        <p
          v-text="$t('general.button_edit')"
          class="text-white"></p>
      </div>
      <input
        type="file"
        accept="image/*"
        ref="avatarInput"
        name="avatar"
        class="opacity-0 h-24 w-24 absolute z-10"
        @change="fileSelected"
      >
    </div>
    <div class="form-fieldset">
      <p
        v-text="$t('the_account_edit.label_firstname')"
        class="form-label">
      </p>
      <input
        type="text"
        v-model="updatedUser.firstname"
        name="firstname"
        v-validate="'required'"
        data-vv-validate-on="submit"
        class="form-input"
        :placeholder="$t('general.placeholder_required')"
      >
    </div>
    <div class="form-fieldset">
      <p
        v-text="$t('the_account_edit.label_lastname')"
        class="form-label">
      </p>
      <input
        type="text"
        v-model="updatedUser.lastname"
        name="lastname"
        v-validate="'required'"
        data-vv-validate-on="submit"
        class="form-input"
        :placeholder="$t('general.placeholder_required')"
      >
    </div>
    <div
      v-if="user.phone"
      class="form-fieldset"
    >
      <p
        v-text="$t('the_account_edit.label_phone')"
        class="form-label">
      </p>
      <input
        type="text"
        :value="user.phone"
        class="form-input"
        :disabled="true"
      >
    </div>
    <div
      v-if="user.email"
      class="form-fieldset"
    >
      <p
        v-text="$t('the_account_edit.label_email')"
        class="form-label">
      </p>
      <input
        type="text"
        :value="user.email"
        class="form-input"
        :disabled="true"
      >
    </div>
  </form>
</template>

<script>
  import VBack from '@/components/common/VBack';
  import validator from '@/mixins/validator';
  import cloneDeep from 'lodash.clonedeep';

  export default {
    name: 'TheAccountEdit',
    components: {
      VBack,
    },
    mixins: [validator],
    data() {
      return {
        updatedUser: null,
        loading: false,
        error: null,
      };
    },
    computed: {
      user() {
        return this.$store.getters['auth/user'];
      },
      avatar() {
        if (this.updatedUser.avatar instanceof File) {
          return URL.createObjectURL(this.updatedUser.avatar);
        }

        return this.updatedUser.avatar ? this.updatedUser.avatar.medium : null;
      },
    },
    created() {
      this.updatedUser = cloneDeep(this.user);
    },
    methods: {
      fileSelected(event) {
        if (event.target.files.length > 0 && event.target.files[0].type.split('/')[0] === 'image') {
          [this.updatedUser.avatar] = event.target.files;
        } else {
          this.updatedUser.avatar = this.user.avatar;
        }
      },
      update() {
        if (this.loading) {
          return;
        }

        this.error = null;

        this.$validator.validate().then(() => {
          if (this.$validator.errors.items.length > 0) {
            return;
          }

          this.loading = true;

          this.$store.dispatch('auth/updateUser', this.updatedUser)
            .then(() => {
              this.loading = false;

              if (this.$route.query.redirect) {
                this.$router.push({ path: this.$route.query.redirect });
              } else {
                this.$router.push({ name: 'account-detail' });
              }
            })
            .catch(this.handleError);
        });
      },
      handleError(error) {
        this.loading = false;

        if (!error.response) {
          this.error = this.$t('general.label_error');

          return;
        }

        this.error = error.response.data.message;

        if (Object.values(error.response.data.errors).length > 0) {
          [[this.error]] = Object.values(error.response.data.errors);
        }
      },
    },
  };
</script>
