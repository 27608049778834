var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user !== null
    ? _c(
        "form",
        {
          staticClass: "flex flex-col",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.update($event)
            }
          }
        },
        [
          _c(
            "nav",
            {
              staticClass:
                "flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end"
            },
            [
              _c("v-back", {
                attrs: {
                  close: true,
                  fallback: _vm.$router.resolve({ name: "account-detail" })
                }
              }),
              _c("p", {
                staticClass: "font-semibold w-full ml-12 text-center",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_account_detail.label_user_profile")
                  )
                }
              }),
              _c("button", {
                staticClass: "btn btn-blue rounded-full py-1 px-3 focus:shadow",
                class: { "btn-loading": _vm.loading },
                attrs: { type: "submit", disabled: _vm.loading },
                domProps: { textContent: _vm._s(_vm.$t("general.button_save")) }
              })
            ],
            1
          ),
          _c("ul", { staticClass: "list-disc list-inside" }, [
            _vm.error
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.error) }
                })
              : _vm._e(),
            _vm.errors.has("firstname")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: {
                    textContent: _vm._s(_vm.errors.first("firstname"))
                  }
                })
              : _vm._e(),
            _vm.errors.has("lastname")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: {
                    textContent: _vm._s(_vm.errors.first("lastname"))
                  }
                })
              : _vm._e(),
            _vm.errors.has("phone")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.errors.first("phone")) }
                })
              : _vm._e(),
            _vm.errors.has("email")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.errors.first("email")) }
                })
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass:
                "w-full flex justify-center py-6 border-b border-blue-500 relative"
            },
            [
              !_vm.avatar
                ? _c("img", {
                    staticClass: "h-24 w-24 rounded-full",
                    attrs: {
                      src: require("@/assets/images/avatar_placeholder.svg"),
                      alt: _vm.$t("general.label_profile_picture")
                    }
                  })
                : _vm._e(),
              _vm.avatar
                ? _c("img", {
                    staticClass: "h-24 w-24 rounded-full",
                    attrs: {
                      src: _vm.avatar,
                      alt: _vm.$t("general.label_profile_picture")
                    }
                  })
                : _vm._e(),
              _c("div", {
                staticClass:
                  "h-24 w-24 absolute bg-black opacity-50 rounded-full"
              }),
              _c(
                "div",
                {
                  staticClass:
                    "h-24 w-24 absolute rounded-full flex\n      justify-center items-center pointer-events-none"
                },
                [
                  _c("p", {
                    staticClass: "text-white",
                    domProps: {
                      textContent: _vm._s(_vm.$t("general.button_edit"))
                    }
                  })
                ]
              ),
              _c("input", {
                ref: "avatarInput",
                staticClass: "opacity-0 h-24 w-24 absolute z-10",
                attrs: { type: "file", accept: "image/*", name: "avatar" },
                on: { change: _vm.fileSelected }
              })
            ]
          ),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("p", {
              staticClass: "form-label",
              domProps: {
                textContent: _vm._s(_vm.$t("the_account_edit.label_firstname"))
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedUser.firstname,
                  expression: "updatedUser.firstname"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "text",
                name: "firstname",
                "data-vv-validate-on": "submit",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.updatedUser.firstname },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.updatedUser, "firstname", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("p", {
              staticClass: "form-label",
              domProps: {
                textContent: _vm._s(_vm.$t("the_account_edit.label_lastname"))
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedUser.lastname,
                  expression: "updatedUser.lastname"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "text",
                name: "lastname",
                "data-vv-validate-on": "submit",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.updatedUser.lastname },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.updatedUser, "lastname", $event.target.value)
                }
              }
            })
          ]),
          _vm.user.phone
            ? _c("div", { staticClass: "form-fieldset" }, [
                _c("p", {
                  staticClass: "form-label",
                  domProps: {
                    textContent: _vm._s(_vm.$t("the_account_edit.label_phone"))
                  }
                }),
                _c("input", {
                  staticClass: "form-input",
                  attrs: { type: "text", disabled: true },
                  domProps: { value: _vm.user.phone }
                })
              ])
            : _vm._e(),
          _vm.user.email
            ? _c("div", { staticClass: "form-fieldset" }, [
                _c("p", {
                  staticClass: "form-label",
                  domProps: {
                    textContent: _vm._s(_vm.$t("the_account_edit.label_email"))
                  }
                }),
                _c("input", {
                  staticClass: "form-input",
                  attrs: { type: "text", disabled: true },
                  domProps: { value: _vm.user.email }
                })
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }